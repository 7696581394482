// Translated
// Migrated
<template>
  <div class="form-group">
    <label :for="id">
      <slot name="label" />
    </label>
    <input
      :id="id"
      class="form-control w-full"
      :type="type"
      :name="id"
      :value="modelValue"
      :placeholder="placeholder"
      :class="{ 'is-invalid': isInvalid }"
      @input="$emit('update:modelValue', $event.target.value)"
      @keyup.enter="$emit('onClickEnter')"
    />
    <div
      v-show="isInvalid"
      class="invalid-feedback"
    >
      <slot
        name="validation"
        :v="v"
      />
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    v$: {
      type: Object,
      default: () => {},
    },

    id: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      default: 'text',
    },

    placeholder: {
      type: String,
      default: '',
    },

    modelValue: {
      type: String,
      default: '',
    },
  },

  emits: ['onClickEnter', 'update:modelValue'],

  computed: {
    v () {
      return this.v$?.[this.id] || { required: false }
    },

    shouldValidate () {
      return this.v$ && this.v$?.[this.id]
    },

    isInvalid () {
      if (!this.shouldValidate) {
        return false
      }
      return this.v$?.$errors.length > 0
    },
  },
})
</script>
